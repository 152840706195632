import * as React from 'react'
import Layout from '../components/layout'
import { title } from '../components/my-module.css'
import { hpmain } from '../components/layout.module.css'
import instagramlogo from '../images/instagramlogo.png';

const ContactPage = () => {

  return (
    <Layout pageTitle="Contact">
      <div className={hpmain}>
        <div class={"card clickable"}>
          <p>Για οποιαδήποτε περαιτέρω πληροφορία ή διευκρίνιση, παρακαλώ μη διστάσετε να επικοινωνήσετε μαζί μας. &#9825;</p>
          <h4>Email</h4>
          <a href={"mailto:joancraftsgr@gmail.com"}>
            <p>joancraftsgr@gmail.com</p>
          </a>
          <h4>Instagram</h4>
          <a href={"https://instagram.com/joancraftsgr"} target="_blank">
            joancraftsgr <p>
              <img className={"insticon"} src={instagramlogo} /> </p>
          </a>
          <p>Για να παραγγείλετε, παρακαλώ στείλτε μας μήνυμα στο Instagram. &#9825;</p>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
